.carousel {
  width: 90%;
}

@media only screen and (min-width: 992px) {
  .leftbtn {
    padding-left: 0 !important;
  }

  .rightbtn {
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  .carousel {
    width: 100%;
  }
}

.carousel :global .swiper-slide {
  transition: opacity 0.4s ease-in-out, transform 0.1s ease-in-out !important;
  margin: auto 0;
}

.carousel :global .swiper-slide.swiper-slide-next,
.carousel :global .swiper-slide.swiper-slide-prev {
  opacity: 20%;
}

.carousel :global .swiper-slide:not(.swiper-slide-visible) {
  opacity: 0%;
}

.carouselImage {
  border-radius: 32px;
  width: 100%;
}

.igBtn {
  background: linear-gradient(
    90deg,
    #ffc658 0%,
    #f62b37 10.3%,
    #c82091 29.6%,
    #7d31b9 62.5%,
    #3542e0 100%
  ) !important;
  border: none !important;
}

.fbBtn,
.fbBtn:hover {
  background: #1e4fad !important;
  border: none !important;
}

@media only screen and (max-width: 992px) {
  .info {
    text-align: center;
  }

  .info button {
    font-size: 0.8rem !important;
  }

  .date {
    font-size: 2rem !important;
  }
}
