.nav :global .navbar-nav button {
  border: 2px solid green;
  background-color: white;
  color: black;
  max-width: 300px;
}

.nav :global .navbar-nav button:hover {
  border: 2px solid green;
  background-color: green;
  color: white;
}

.nav :global .nav-link:hover {
  border-bottom: 2px solid green;
}

.nav :global .nav-link:hover {
  border-bottom: 2px solid green;
}
