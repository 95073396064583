.contentContainer {
  max-width: 1100px;
  border-radius: 1.5rem;
}

@media screen and (min-width: 992px) {
  .bigContainer {
    margin-left: 360px !important;
  }
}
