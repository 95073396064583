@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
html,
body,
#root,
#root > div.vstack,
#root > div.hstack {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span.section {
  display: block;
  transform: translate(0, -100px);
}

.page-link {
  color: #006a03 !important;
}

.active > .page-link {
  background-color: #006a03 !important;
  color: white !important;
  border-color: #006a03 !important;
}

.form-select:focus {
  border-color: rgba(0, 106, 3, 0.4) !important;
  box-shadow: 0 0 0 0.25rem rgba(0, 106, 3, 0.2) !important;
}
