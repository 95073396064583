.formContainer {
  border: 3.5px solid #006a03;
  border-radius: 16px;
  padding: 2rem;
}

.formContainer form > div > input,
.formContainer form > div > textarea {
  font-size: 1.3rem;
}

/* form-group spacing */
.formContainer form > div {
  margin: 2rem;
}

/* error message */
.formContainer form > div > div {
  text-align: left;
  color: red;
  margin: 0.7rem 0;
}

.submitBtn {
  width: 40%;
  font-size: 1.4rem;
}

@media only screen and (max-width: 992px) {
  .formContainer {
    padding: 1rem;
  }

  .formContainer form > div {
    margin: 1rem;
  }
}
