.premiumCard {
  border-radius: 16px !important;
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.651);
}

.premiumCard img {
  border-radius: 16px 16px 0 0;
  cursor: pointer;
}

.premiumCard :global .card-title {
  margin: 1rem auto;
  font-weight: 700;
  font-size: 1.3rem;
  cursor: pointer;
}

.partnerContainer {
  border-radius: 32px;
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.651);
  margin: 1rem auto 4rem auto;
  padding: 2rem 3rem !important;
}

.defaultCursor {
  cursor: default !important;
}
