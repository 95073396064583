.err {
  color: red;
}

.vid {
  width: 50%;
  margin: 0 auto;
}

.scanCustomButton {
  background-color: #1d911d !important;
  border: 1px solid #156b15 !important;
  color: white !important;
  font-weight: 700 !important;
}

.offButton {
	background-color: #d6d6d6 !important;
	border: 1px solid #bcbcbc !important;
	color: #7a7a7a !important;
	opacity: 0.75;
}

@media only screen and (max-width: 992px) {
  .vid {
    width: 100%;
  }
}
