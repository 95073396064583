/* the element with the white background of the modal is 2 divs deep  */
.transparrentModal > div > div {
  background-color: rgba(0, 0, 0, 0.8);
}

.formContainer {
  max-width: 1100px;
}

.formContainer .tabs button {
  border-width: 0;
  background-color: rgba(0, 0, 0, 0) !important;
  color: white !important;
  font-size: 2.2rem !important;
  font-weight: 300;
  margin: 0.5rem 1rem;
}

.formContainer .tabs :global button.active {
  font-weight: 800;
  border-bottom: 4px solid white;
}

.tabs {
  margin: 2rem auto !important;
}
