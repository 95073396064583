.section {
  margin: 3rem 0;
}

.section h1 {
  font-weight: 700;
  font-size: 2.5rem;
  margin: 2rem auto;
}

.section h2 {
  font-size: 2.4rem;
  margin: 1rem auto 0 auto;
}
