.targBtn {
  background-color: #1d911d !important;
  border-radius: 16px !important;
  font-weight: 700 !important;
  padding: 0.6rem 0.9rem !important;
  border: 1px solid #156b15 !important;
}

.targBtn:hover {
  background-color: #156b15 !important;
}

.targBtn:active {
  background-color: #0f4d0f;
}
