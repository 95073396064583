.form :global .row {
  --bs-gutter-x: 4.5rem;
}

.btnContainer {
  margin-top: 2rem !important;
}

/* btn2 ca sa dea overwrite la stilurile de bootstrap */

.form .btn,
.btn.btn2 {
  width: 200px;
  padding: 0.75rem;
  margin: auto;
  margin-top: 1rem;

  background-color: #efefef;
  color: black;

  font-weight: 500;
  font-size: 1.25rem;

  border-radius: 32px;
  border-width: 0;
}

.form .btn:hover,
.btn.btn2 {
  background-color: white !important;
  color: black !important;
}

.tag {
  background-color: rgba(55, 54, 54, 0.36) !important;
  font-size: 0.6rem !important;
}
.tagSmall {
  font-size: 0.6rem !important;
}
.tagBig {
  font-size: 1rem !important;
}
