.dataPoint {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataPoint p {
  font-size: 1.5rem;
  margin: auto 0;
}

.dataPoint h3 {
  font-size: 4rem;
  font-weight: 700;
  color: #006a03;
  margin: auto 1.8rem;
}

@media only screen and (max-width: 992px) {
  .dataPoint p {
    width: 60%;
    font-size: 1rem;
    text-align: left;
  }

  .dataPoint h3 {
    width: 40%;
    font-size: 2.7rem;
    text-align: left;
  }
}
