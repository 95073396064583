.swiper {
  max-width: 600px;
  box-shadow: 0px 3px 6px rgba(51, 51, 51, 0.651);
  border-radius: 32px;
  margin-top: 2.5rem;
}

.swiper :global .swiper-slide {
  padding: 2.5rem !important;
}

.swiper img {
  width: 260px;
  height: 260px;
}

.swiper h2 {
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.swiper p {
  color: #6c6262;
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

@media only screen and (max-width: 992px) {
  .swiper img {
    width: 160px;
    height: 160px;
  }

  .swiper h2 {
    font-size: 1.5rem;
  }

  .swiper p {
    font-size: 0.8rem;
  }
}

/*
* face bulinele de la carusel centrate vertical, verzi si mai mari
*/

.swiper :global .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.swiper :global .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 2px solid green;
}

.swiper :global .swiper-pagination-bullet-active {
  height: 20px;
  width: 20px;
  background-color: green;
}

/*
* fa sagetile sa fie verzi
*/

.swiper :global .swiper-button-next::after,
.swiper :global .swiper-button-prev::after,
.swiper :global .swiper-button-next,
.swiper :global .swiper-button-prev {
  content: "";
  height: 64px;
  width: 35px;
}

@media only screen and (max-width: 992px) {
  .swiper :global .swiper-button-next::after,
  .swiper :global .swiper-button-prev::after,
  .swiper :global .swiper-button-next,
  .swiper :global .swiper-button-prev {
    background-size: contain;
    background-repeat: no-repeat;
    height: 32px;
    width: 17px;
  }
}

.swiper :global .swiper-button-next::after {
  background-image: url("../../../public/right_arrow.svg");
}

.swiper :global .swiper-button-prev::after {
  background-image: url("../../../public/left_arrow.svg");
}
