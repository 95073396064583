.dataRow {
  border-bottom: 1px solid #707070;
  margin: 1rem;
  padding: 0.6rem 0;
  font-weight: 700;
  color: #333333;
  font-size: 1.4rem !important;
  word-wrap: break-word;
}

.dataRow input,
.dataRow select,
.btnRow button {
  font-size: 1.4rem !important;
}

.tagRow {
  min-width: max-content;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tagRow button {
  font-size: 1rem !important;
  width: max-content;
}

@media only screen and (max-width: 992px) {
  .dataRow,
  .dataRow input,
  .dataRow select,
  .btnRow button {
    font-size: 0.8rem !important;
  }

  .tagRow button {
    font-size: 0.6rem !important;
  }
}

.editBtn {
  cursor: pointer;
  object-fit: contain;
}

.deleteBtn {
  background-color: red !important;
  border-color: red !important;
}

.deleteBtn:hover {
  background-color: red !important;
  border-color: red !important;
}
