textarea {
  appearance: none;
  font-size: 1rem;
  border: 1px solid #ced4da;
  color: #4a5568;
  border-radius: 10px;
  resize: none;
  background-color: var(--gray-100);
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  overflow: hidden;
  max-width: 100%;
}

textarea:focus {
  outline: none;
  border: 2px solid #000;
}

.customCodeField {
  text-align: center;
  width: 15%;
  min-width: 100px;
}
