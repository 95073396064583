.questionText {
  margin-top: 1rem;

  color: black;

  font-size: 1.2rem;
}

.smTargBtn {
  max-width: 400px;
  margin: auto;
}

@media only screen and (max-width: 992px) {
  .smTargBtn {
    font-size: 0.8rem !important;
    padding: 0.8rem;
  }
}

.options {
  color: #1d911d !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.error {
  color: red;
}

.noMarginBottom p {
  margin-bottom: 0px;
}
