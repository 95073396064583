.card {
  width: 100%;
  height: 100%;
  max-width: 360px;

  align-items: center;
  justify-content: center;

  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.149);
  border-radius: 24px;
  padding: 1rem;
}

.card img {
  width: 160px;
  height: 160px;
  object-fit: cover;

  border: 2px solid #006a03;
  border-radius: 100%;
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.149);
}

.card h2 {
  font-size: 1.4rem !important;
  font-weight: 700;
  margin: 0.8rem;
  color: #006a03;
}

.card h3 {
  font-size: 1.1rem !important;
  font-weight: 700;
  margin: 0.5rem;
}

.card p {
  font-size: 1rem;
  margin: 0.2rem;
  color: #777575;
}

.newCard {
  max-width: 360px;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  border-radius: 24px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.newCard img {
  width: 160px;
  height: 160px;
  object-fit: cover;

  border: 2px solid #006a03;
  border-radius: 100%;
  box-shadow: 0px 2px 5px rgba(51, 51, 51, 0.149);
}

.newCard h2 {
  font-size: 1.4rem !important;
  font-weight: 700;
  margin: 0.8rem;
  color: #006a03;
}

.centered h3 {
  font-size: 1.1rem !important;
  font-weight: 700;
  margin: 0.5rem;
}

.newCard p {
  font-size: 1rem;
  margin: 0.2rem;
  color: #777575;
}
.parentCard {
  display: flex;
  align-items: center;
  justify-content: center;
}
