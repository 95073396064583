.sidebar {
  height: 100vh;
  border-top-right-radius: 90px;
  box-shadow: 0 3px 6px rgba(51, 51, 51, 0.478);
  min-width: 360px;
  max-width: 360px;
}

.sidebarLink {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  color: black !important;

  width: 100%;
  margin: 0.5rem 0px;

  border-left: 6px solid white; /* overwrite la culoare de bootstrap */
}

.sidebarLink:hover {
  margin-left: 0;
  border-left: 6px solid green;

  color: #006a03 !important;
  fill: #006a03;

  background: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(196, 222, 196, 0.231) 100%
  );
}

.sidebarLink div {
  padding: 0.7rem 2.5rem;
}

@media screen and (max-width: 992px) {
  .sidebar {
    height: 100%;
    border-top-right-radius: 0px;
    max-width: 100%;
  }

  .sidebarLink div {
    padding: 0rem;
    text-align: center;
    width: 100%;
  }

  .sidebarLink {
    font-size: 1rem !important;
  }
}
